import React from "react"
import styled from "styled-components"
import { Colors } from "../colors/Colors"
import { Link } from "gatsby"
import Img from "gatsby-image"
// import { motion } from "framer-motion"
import { motion } from "framer-motion/dist/framer-motion"

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 750px) {
    border-bottom: 1px solid #efefef;
    padding-bottom: 20px;
  }
`
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 320px;
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 16px;

  @media (max-width: 750px) {
    display: ${props => (props.noImage ? "none" : "block")};
  }
`
const BlogTitle = styled.h3`
  font-size: 1.2rem;
  color: #5b4518;
  font-family: "Articulat";
  font-weight: 600;
  margin-bottom: 8px;

  :hover {
    text-decoration: underline;
  }
`
const Author = styled.p`
  margin-bottom: 8px;
  font-family: "Articulat";
  font-weight: 500;
  font-style: italic;
  font-size: 14px;
`
const Excerpt = styled.div`
  p {
    margin: 0;
    font-size: 0.8rem;
    line-height: 1.4;
    max-width: 50ch;
  }
  margin-bottom: 8px;
`
const ReadMore = styled.button`
  margin: 0;
  margin-left: -6px;
  background: none;
  border: none;
  width: fit-content;
  /* color: ${Colors.gold};/ */
  font-family: "Articulat";
  font-size: 14px;
  text-decoration: underline;
  text-decoration-thickness: 3px;
    text-decoration-color: ${Colors.cream};
  font-weight: 500;
  transition: all 0.2s ease-in-out;

  :hover {
    text-decoration-thickness: 4px;
    text-decoration-color: ${Colors.gold};

  }
`

const BlogItem = ({ post, index }) => (
  <Container
    initial={{ opacity: 0, y: 20 }}
    whileInView={{
      opacity: 1,
      y: 0,
    }}
    transition={{
      duration: 0.4,
      ease: [0, 0.55, 0.45, 1],
      delay: index % 2 === 0 ? 0 : 0.2,
    }}
    viewport={{
      once: true,
      amount: 0.2,
    }}
  >
    {post?.featured_media ? (
      <Link to={`/blog/${post.slug}`}>
        <ImageContainer>
          <Img
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
              position: "absolute",
              top: 0,
              left: 0,
              objectFit: "cover",
            }}
            fadeIn
            objectFit="cover"
            fluid={post?.featured_media.localFile.childImageSharp.fluid}
          />
        </ImageContainer>
      </Link>
    ) : (
      <Link to={`/blog/${post.slug}`}>
        <ImageContainer noImage>
          <img
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
              position: "absolute",
              top: 0,
              left: 0,
              objectFit: "cover",
            }}
            src={require("../images/post-placeholder.png")}
          />
        </ImageContainer>
      </Link>
    )}
    <BlogTitle>
      <Link
        style={{ color: "inherit", textDecoration: "none" }}
        to={`/blog/${post.slug}`}
      >
        {post.title}
      </Link>
    </BlogTitle>
    <Author>{post.date}</Author>
    <Excerpt dangerouslySetInnerHTML={{ __html: post.excerpt }} />
    <Author>by Shenilee Hazell</Author>
    <ReadMore>
      <Link
        style={{ color: "inherit", textDecoration: "none" }}
        to={`/blog/${post.slug}`}
      >
        Read More
      </Link>
    </ReadMore>
  </Container>
)

export default BlogItem
