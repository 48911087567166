import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Colors } from "../colors/Colors"
import BlogItem from "../components/blog-item"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicyPage = ({ data }) => {
  const blogPosts = data.blogPosts.edges
  return (
    <Layout noHeader>
      <SEO title="Privacy Policy" />
      <Container>
        <Title>Privacy Policy</Title>
        <Body>
          At Smile Inn Dental, we are committed to protecting the privacy of our
          clients. This Privacy Policy explains how we collect, use, and share
          information about you when you use our services.
        </Body>
        <SectionTitle>Information We Collect</SectionTitle>
        <p>We may collect the following information about you:</p>
        <ul>
          <li>
            Personal information, such as your name, email address, and phone
            number
          </li>
          <li>Dental and medical history</li>
          <li>Payment information, such as credit card or insurance details</li>
          <li>Any other information you choose to provide to us</li>
        </ul>
        <SectionTitle>How We Use Your Information</SectionTitle>
        <ul>
          <li>Provide dental care and treatment</li>
          <li>Process payments and insurance claims</li>
          <li>Communicate with you about your dental care</li>
          <li>Improve our services and develop new products</li>
        </ul>
        <SectionTitle>Data Security</SectionTitle>
        <p>
          We take appropriate security measures to protect your information from
          unauthorized access, use, or disclosure.
        </p>
        <SectionTitle>Changes to Our Privacy Policy</SectionTitle>
        <p>
          We may update our Privacy Policy from time to time. We will post any
          changes on this page, and we encourage you to review our Privacy
          Policy regularly.
        </p>

        <SectionTitle>Contact Us</SectionTitle>
        <p>
          If you have any questions about our Privacy Policy, please contact us
          by email at smileinntt@gmail.com.
        </p>
      </Container>
    </Layout>
  )
}

export default PrivacyPolicyPage

const Container = styled.main`
  padding: 20vh 20px;
  max-width: 780px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: "Articulat";

  > img {
    margin: 0 auto;
    margin-bottom: 18px;
    text-align: center;
  }

  @media (max-width: 750px) {
    padding: 6vh 20px;
  }
`
const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  color: ${Colors.gold};
`
const Body = styled.p`
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
  /* max-width: 55ch; */
  margin: 0 auto;
  margin-bottom: 100px;
`
const SectionTitle = styled.h2`
  font-family: "Articulat";
  font-weight: 600;
  font-size: 1.2rem;
`
const BlogSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-bottom: 100px;

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`
export const blogPostsQuery = graphql`
  query($blogs: String = "Blog") {
    blogPosts: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $blogs } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          excerpt
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
